import { Settings as LuxonSettings } from 'luxon';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';

import 'src/components/layout/global.scss';
import { reduxWrapper } from 'src/redux/store';

// Use Eastern Time instead of UTC for server-side rendering
if (typeof window === 'undefined') {
    LuxonSettings.defaultZone = 'America/New_York';
}

const gtagRouteHandler = (url) => {
    if (typeof window.gtag !== 'undefined' && url) {
        window.gtag('config', CONFIG.googleAnalytics.measurementId, {
            page_path: url,
        });
    }
};

const App = ({ Component, pageProps }) => {
    const router = useRouter();

    // Register route handler for Google Analytics
    useEffect(() => {
        router.events.on('routeChangeComplete', gtagRouteHandler);
        return () => router.events.off('routeChangeComplete', gtagRouteHandler);
    }, [router.events]);

    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
            </Head>
            <Component {...pageProps} />
            <Toaster />
        </>
    );
};

export default reduxWrapper.withRedux(App);
