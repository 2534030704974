var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"cbf7e22e837a96e6406fb7184d35d3a6eabbb8ca"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Integrations } from '@sentry/tracing';

const apiHostname = new URL(CONFIG.apiUrl).hostname;

Sentry.init({
    enabled: CONFIG.sentry.enabled,
    dsn: CONFIG.sentry.dsn,
    environment: CONFIG.env,
    release: process.env.GIT_COMMIT_SHA || process.env.SENTRY_RELEASE,
    tracesSampler: ({ request }) => {
        // Don't log transactions for healthcheck route
        if (request && request.url.indexOf('/healthcheck') !== -1) {
            return 0;
        }

        return CONFIG.sentry.tracesSampleRate;
    },
    integrations: [
        new Integrations.BrowserTracing({
            // Link API requests to Sentry trace
            // https://docs.sentry.io/platforms/javascript/performance/included-instrumentation/#tracingorigins
            tracingOrigins: [apiHostname],
        }),
    ],
});
