import { composeWithDevTools } from '@redux-devtools/extension';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';

import reducers from './reducers';

const nextReduxReducer = (state = {}, action) => {
    if (action.type === HYDRATE) {
        return {
            ...state, // use previous state
            ...action.payload, // apply delta from hydration
        };
    } else {
        return reducers(state, action);
    }
};

const makeStore = () => {
    return createStore(
        nextReduxReducer,
        undefined,
        composeWithDevTools(applyMiddleware(thunk))
    );
};

export const reduxWrapper = createWrapper(makeStore, { debug: false });
