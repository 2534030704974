import { combineReducers } from 'redux';

import * as types from './actionTypes';

const userLoggedIn = (state = false, action) => {
    switch (action.type) {
        case types.loadUserSuccess:
            return !!action.data;
        case types.authLoginSuccess:
            return true;
        case types.loadUserFail:
        case types.loadUserAuthRequired:
        case types.authLogoutSuccess:
            return false;
        default:
            return state;
    }
};

const userLoading = (state = true, action) => {
    switch (action.type) {
        case types.loadUserSuccess:
        case types.loadUserFail:
        case types.loadUserAuthRequired:
            return false;
        default:
            return state;
    }
};

const userData = (state = {}, action) => {
    switch (action.type) {
        case types.loadUserSuccess:
        case types.authLoginSuccess:
        case types.loadUserAuthRequired:
            return action.data;
        case types.authLoginFail:
        case types.loadUserFail:
        case types.authLogoutSuccess:
            return {};
        default:
            return state;
    }
};

export default combineReducers({
    user: combineReducers({
        loggedIn: userLoggedIn,
        loading: userLoading,
        data: userData,
    }),
});
