export const loadUserStart = 'LOAD_USER-START';
export const loadUserSuccess = 'LOAD_USER-SUCCESS';
export const loadUserFail = 'LOAD_USER-FAIL';
export const loadUserAuthRequired = 'LOAD_USER-AUTH_REQUIRED';

export const authLoginStart = 'AUTH_LOGIN-START';
export const authLoginSuccess = 'AUTH_LOGIN-SUCCESS';
export const authLoginFail = 'AUTH_LOGIN-FAIL';
export const authLogoutStart = 'AUTH_LOGOUT-START';
export const authLogoutSuccess = 'AUTH_LOGOUT-SUCCESS';
export const authLogoutFail = 'AUTH_LOGOUT-FAIL';
